::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $grey-400;
}

::-webkit-scrollbar-thumb {
  background: $grey-800;
}
