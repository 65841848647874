$white: #fff !default;
$grey-100: #f8f9fa !default;
$grey-200: #e9ecef !default;
$grey-300: #dee2e6 !default;
$grey-400: #ced4da !default;
$grey-500: #adb5bd !default;
$grey-600: #727272 !default;
$grey-700: #495057 !default;
$grey-800: #343a40 !default;
$grey-900: #212529 !default;
$black: #000 !default;

$brand-blue: #0c4074;
$brand-blue-light-1: #b1dde8;

$brand-red: #e4291b;
$brand-red-light-1: #993333;
$brand-red-light-2: #c93b3b;

$brand-green: #3D6434;

$brand-amber: #b66229;
$brand-amber-light-1: #e18933;
$brand-amber-light-2: #ffb974;
