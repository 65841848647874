.l-container {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}

.l-gutters {
  padding-left: 15px;
  padding-right: 15px;

  @include media('sm-and-up') {
    padding-left: 40px;
    padding-right: 40px;
  }
}
